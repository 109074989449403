import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faServer } from "@fortawesome/free-solid-svg-icons";

const BOT_ID = "1340801170083414160"; // Replace with your actual bot's ID

function BotStatus() {
  const [status, setStatus] = useState("Checking...");

  useEffect(() => {
    async function fetchBotStatus() {
      try {
        const response = await fetch(`https://discord.com/api/v10/users/${BOT_ID}`, {
          headers: { Authorization: `Bot MTM0MDgwMTE3MDA4MzQxNDE2MA.G36CPM.rulZHmoFHXfTz-yfQKs_31dxbBfQwZAiAl5kqY` }, // Requires Bot Token
        });

        if (response.ok) {
          setStatus(<FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />); // Online
        } else {
          setStatus(<FontAwesomeIcon icon={faTimesCircle} className="text-red-500" />); // Offline
        }
      } catch (error) {
        setStatus(<FontAwesomeIcon icon={faCheckCircle} className="text-red-500" />); // Offline
      }
    }

    fetchBotStatus();
    const interval = setInterval(fetchBotStatus, 10000); // Refresh every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="page-container">
      <h1 className="text-3xl font-bold text-blue-700"><FontAwesomeIcon icon={faServer} /> Ticket Genie Bot Status</h1>
      <p className="text-lg mt-4">Current Status: <strong>{status} Online</strong></p>
    </div>
  );
}

export default BotStatus;