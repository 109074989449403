import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFileContract, faShieldAlt, faChartBar, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  return (
    <nav className="bg-blue-600 text-white p-4 shadow-md">
      <div className="container mx-auto flex justify-center items-center space-x-6">
        <Link to="/" className="text-xl font-bold"><FontAwesomeIcon icon={faHome} /> Ticket Genie</Link>
        <Link to="/" className="hover:underline"><FontAwesomeIcon icon={faHome} /> Home</Link>
        <Link to="/terms" className="hover:underline"><FontAwesomeIcon icon={faFileContract} /> Terms of Service</Link>
        <Link to="/privacy" className="hover:underline"><FontAwesomeIcon icon={faShieldAlt} /> Privacy Policy</Link>
        <Link to="/status" className="hover:underline"><FontAwesomeIcon icon={faChartBar} /> Bot Status</Link>
        <a href="https://dashboard.ticketgenie.pro" target="_blank" rel="noopener noreferrer"
          className="bg-purple-500 px-3 py-2 rounded text-white hover:bg-purple-700 transition">
          Dashboard
        </a>
        <button
          onClick={() => setDarkMode(!darkMode)}
          className="bg-gray-800 px-3 py-2 rounded text-white hover:bg-gray-600 transition"
        >
          <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
        </button>
      </div>
    </nav>
  );
}

export default Navbar;