import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="bg-[#6a0dad] text-white text-center p-6 mt-8">
      <div className="flex flex-col items-center space-y-3">
        {/* Social Media Links */}
        <div className="flex space-x-4">
          <a href="https://discord.gg/gQaw2T26rr" target="_blank" rel="noopener noreferrer" className="hover:underline">
            <FontAwesomeIcon icon={faDiscord} /> Discord
          </a>
        </div>

        {/* Copyright Notice */}
        <p>&copy; {new Date().getFullYear()} Ticket Genie. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
