import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div className="page-container">
      <h1 className="text-5xl font-bold text-blue-700">🎟️ Ticket Genie</h1>
      <p className="text-lg mt-4 max-w-lg">
        The ultimate Discord bot for managing support tickets effortlessly. Automate your workflow and enhance your server’s support system!
      </p>
      <Link to="https://dashboard.ticketgenie.pro">
        <button className="bg-blue-600 text-white px-6 py-3 rounded mt-6 hover:bg-blue-800 transition">
          Get Started 🚀
        </button>
      </Link>
    </div>
  );
}

export default HomePage;