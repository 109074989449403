import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

function PrivacyPolicy() {
  return (
    <div className="page-container">
      <h1 className="text-3xl font-bold text-blue-700">Privacy Policy</h1>
      <p className="text-gray-700 mt-4">Last Updated: 15th March 2025</p>

      <section className="mt-6 text-left max-w-3xl">
        <h2 className="text-xl font-semibold mt-4">1. Introduction</h2>
        <p className="text-gray-800 mt-2">
          Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your data.
        </p>

        <h2 className="text-xl font-semibold mt-4">2. Information We Collect</h2>
        <p className="text-gray-800 mt-2">
          - We may collect Discord user IDs, server IDs, and ticket-related data.<br />
          - No personal data (such as email addresses) is collected unless provided voluntarily.
        </p>

        <h2 className="text-xl font-semibold mt-4">3. How We Use Your Information</h2>
        <p className="text-gray-800 mt-2">
          - To provide and improve Ticket Genie’s services.<br />
          - To troubleshoot issues and enhance user experience.<br />
          - We do NOT sell or share your data with third parties.
        </p>

        <h2 className="text-xl font-semibold mt-4">4. Data Security</h2>
        <p className="text-gray-800 mt-2">
          - We implement appropriate security measures to protect user data.<br />
          - While we strive for security, we cannot guarantee complete protection.
        </p>

        <h2 className="text-xl font-semibold mt-4">5. Third-Party Services</h2>
        <p className="text-gray-800 mt-2">
          - Ticket Genie integrates with Discord, which has its own <a href="https://discord.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Privacy Policy</a>.<br />
          - We are not responsible for how Discord handles user data.
        </p>

        <h2 className="text-xl font-semibold mt-4">6. Changes to This Policy</h2>
        <p className="text-gray-800 mt-2">
          We may update this Privacy Policy as needed. Changes will be posted on this page.
        </p>

        <h2 className="text-xl font-semibold mt-4">7. Contact Us</h2>
        <p className="text-gray-800 mt-2">
          If you have any questions, contact us on <a href="https://discord.gg/gQaw2T26rr" target="_blank" rel="noopener noreferrer" className="hover:underline"><FontAwesomeIcon icon={faDiscord} /> Discord</a>.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
