import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

function TermsOfService() {
  return (
    <div className="page-container">
      <h1 className="text-3xl font-bold text-blue-700">Terms of Service</h1>
      <p className="text-gray-700 mt-4">Last Updated: 15th March 2025</p>

      <section className="mt-6 text-left max-w-3xl">
        <h2 className="text-xl font-semibold mt-4">1. Introduction</h2>
        <p className="text-gray-800 mt-2">
          Welcome to Ticket Genie! By using our Discord bot and services, you agree to these Terms of Service.
          If you do not agree with these terms, please do not use our services.
        </p>

        <h2 className="text-xl font-semibold mt-4">2. Use of Service</h2>
        <p className="text-gray-800 mt-2">
          - You must follow Discord's <a href="https://discord.com/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Service</a>.
          <br />- You may not use Ticket Genie for any illegal or unauthorized purpose.
          <br />- We reserve the right to suspend or terminate access if abuse is detected.
        </p>

        <h2 className="text-xl font-semibold mt-4">3. Liability & Warranty</h2>
        <p className="text-gray-800 mt-2">
          - Ticket Genie is provided "as is" without warranties.
          <br />- We are not responsible for any damage or loss resulting from the use of our bot.
        </p>

        <h2 className="text-xl font-semibold mt-4">4. Changes to Terms</h2>
        <p className="text-gray-800 mt-2">
          We reserve the right to modify these Terms of Service at any time. Changes will be effective immediately upon posting.
        </p>

        <h2 className="text-xl font-semibold mt-4">5. Contact Us</h2>
        <p className="text-gray-800 mt-2">
          If you have any questions, contact us on <a href="https://discord.gg/gQaw2T26rr" target="_blank" rel="noopener noreferrer" className="hover:underline"><FontAwesomeIcon icon={faDiscord} /> Discord</a>.
        </p>
      </section>
    </div>
  );
}

export default TermsOfService;
